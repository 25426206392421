export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REST_COUNTRIES_BASE_URL = 'https://restcountries.com/v3.1';
export const STRIKE_AMAZON_API_BASE_URL =
  'https://strikeapi.s3.eu-west-3.amazonaws.com';
export const STRIKE_AMAZON_PREVIEW_API_BASE_URL =
  'https://strikeapi-preview.s3.eu-west-3.amazonaws.com';
export const PUBLIC_URL = window.location.origin;
export const WEB_SOCKET_BASE_URL = process.env.REACT_APP_WEB_SOCKET_BASE_URL;
export const WEB_SOCKET_TOKEN = process.env.REACT_APP_WEB_SOCKET_ACCESS_TOKEN;
export const TERMS_OF_CONDITIONS_URL =
  'https://gitlab.noveogroup.com/production-php/marmeladz-strike/uploads/6c99142f7002303aa1edb7a4428a41de/TOC.pdf';
export const STRIPE_PUBLIC_KEY =
  'pk_test_51JjOlUD1Va0AATeTDVigSjIMAXKUCjLfDxJ8kcX9pTWIIt4vFrPSOZ29Jzh97quIvz8q2uHCOGHIZbP3McOZQDfA00n6PwqJX7';
export const RECAPTCHA_KEY = '6LfT-QApAAAAAIvYpDl3tn51LDDjEclcwkBx0SKH';
